import './App.css';

function App() {
    function getAge()
    {
        // month is 0-indexed
        const bd = new Date(2005, 2, 1);
        const today = new Date();
        const _m = today.getMonth() - bd.getMonth();
        return (today.getFullYear()) - bd.getFullYear() - ((_m < 0 || (_m === 0 && today.getDate() < bd.getDate()))?1:0)
    }
    
    return (
        <div className={"card"}>
            <div className={"profile"}>
                <img alt={"avatar"} src={"avatar.png"}/>
                
                <div className={"profile-status"}>
                    <h1>jesusqc</h1>
                    <div><span>🟥 offline</span></div>
                </div>
            </div>
            <hr/>
            <div className={"nav"}>
                <a href={"/"}>home</a>
                <span>/</span>
                <a href={"https://github.com/Jesus-QC"} target={"_blank"}>github</a>
                <span>/</span>
                <a href={"https://www.x.com/jesusqc_"} target={"_blank"}>twitter</a>
                <span>/</span>
                <a href={"https://instagram.com/jesusqc_"} target={"_blank"}>instagram</a>
            </div>
            <hr/>
            <div>
                <h2>about me</h2>
                <p>
                    I am a {getAge()}-year-old student from Spain, constantly seeking new challenges.
                    <br/>
                    <br/>
                    Undergraduate student in Computer Science and Engineering.
                </p>
            </div>
            <hr/>
            <div>
                <h2>contact</h2>
                <p>
                    You can reach me via email at <a href="mailto:contact@jesusqc.es" target="_blank">contact@jesusqc.es</a>.
                    Alternatively, you may connect with me on Discord at <a href="https://discord.com/users/430960270433845249" target="_blank">@jesusqc</a>.
                </p>
            </div>
        </div>
    );
}

export default App;
